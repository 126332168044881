/*reset*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
#root {
  position: absolute;
  width: 100%;
  height: 100%;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/*reset*/

@import url("https://fonts.googleapis.com/css?family=Montserrat:400,600,700,800|Raleway:400,500,600,700,800,900&display=swap");

//Theme colors//
$primary-color: #44cdcd;
$secondary-color: #ff567f;
$titles-font: "Raleway", sans-serif;
$content-font: "Montserrat", sans-serif;
//viewport sizes//
$iphoneX: 375px;
$phone: 400px;
$bigPhone: 475px;
$smallTablet: 520px;
$mediumTablet: 650px;
$tablet: 720px;
$largeTablet: 820px;
$smallScreen: 1040px;
$bigScreen: 1150px;
$hugeScreen: 1270px;

//App bar styles//
.MuiPaper-elevation4 {
  box-shadow: none;
}

.makeStyles-links-2 {
  margin-left: 0.4%;
}

.MuiToolbar-root .MuiTypography-body1 {
  font-size: 1.7rem !important;
}

.MuiToolbar-root {
  background-color: $primary-color;
  color: white;
  box-shadow: none;

  .MuiTypography-body1 {
    color: white;
    font-family: $titles-font;
    font-size: 1.5rem;
    font-weight: 600;
    @media screen and (max-width: $smallTablet) {
      display: none;
    }
  }
  .navLinks-div {
    position: absolute;
    right: 15px;
    font-family: $content-font;
    a {
      color: white;
      &:hover {
        color: $secondary-color;
      }
    }
  }
}
#text {
  font-size: 1px;
}
//Typography
.MuiTypography-h3 {
  font-family: $content-font;
}

//Button Styles//

.MuiButton-containedPrimary {
  font-family: $content-font;
  border-radius: 6px;
  box-shadow: none;
  background-color: $secondary-color;
  color: white;
  text-transform: none;
  &:hover {
    background-color: #ff8ba8;
  }
}
.MuiButton-textPrimary {
  color: white;
  background-color: $secondary-color;
  margin-top: 0.5rem;
  margin-bottom: 1rem;

  &:hover {
    background-color: #ff8ba8;
  }
}
.MuiInput-underline:after {
  border-bottom: 2px solid $secondary-color;
}

.MuiButton-root {
  &:focus {
    background-color: none;
  }
}

.MuiMenu-list {
  background: rgb(129, 129, 129);
  color: white;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);

  .MuiMenuItem-root {
    font-family: $content-font;
  }
  a {
    text-decoration: none;
    color: white;
  }
}
//input styles
#standard-basic {
  border-bottom: none;
  box-shadow: none;
  width: 100%;
}
.MuiFilledInput-underline:after {
  border-bottom: 2px solid $secondary-color;
}
label + .MuiInput-formControl {
  margin-top: 5px;
}
.MuiFormLabel-root.Mui-focused {
  color: $secondary-color;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: $secondary-color;
}
input:not([type]),
input[type="text"],
input[type="password"],
input[type="email"],
input[type="url"],
input[type="time"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="tel"],
input[type="number"],
input[type="search"],
textarea.materialize-textarea {
  height: 3rem;
  background-color: inherit;
  border: inherit;
  border-bottom: inherit;
  border-radius: inherit;
  outline: inherit;
  width: inherit;
  font-size: inherit;
  margin: inherit;
  padding: 1rem;
  box-shadow: inherit;
  box-sizing: inherit;
  transition: inherit;
}

//MUI Avatar
.MuiAvatar-root {
  width: 80px;
  height: 80px;
}

//general css//

//Nav//
.logo-div {
  display: flex;
  width: 100%;
  margin-left: 1.5rem;
  align-items: center;
  @media screen and (max-width: $smallTablet) {
    margin-left: 0.3rem;
  }
}
.profile-sec {
  display: flex;
  align-items: center;

  .google-pic {
    width: 47px;
    border-radius: 50%;
    margin-left: 8px;
  }
}

//forms//
.register-form {
  text-align: center;
  display: flex;
  justify-content: center;
  background-color: black;
  background-image: url("https://images.unsplash.com/photo-1533777419517-3e4017e2e15a?ixlib=rb-1.2.1&auto=format&fit=crop&w=2550&q=80");
  background-size: 100%;
  background-repeat: no-repeat;

  .inner-form {
    h1 {
      margin: 1rem 0 1.5rem 0;
      font-family: $titles-font;
      font-size: 1.3rem;
      font-weight: bold;
    }
    background: rgba(255, 255, 255, 0.959);
    margin-top: 20vh;
    margin-bottom: 8rem;
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 2rem 2rem 2rem;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
      0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    @media screen and (max-width: $tablet) {
      width: 60%;
    }
    @media screen and (max-width: $bigPhone) {
      width: 80%;
    }
  }
}
.login-form {
  h1 {
    margin: 1rem 0 1.5rem 0;
    font-family: $titles-font;
    font-size: 1.3rem;
    font-weight: bold;
  }
  text-align: center;
  display: flex;
  justify-content: center;

  .inner-form {
    background: rgba(255, 255, 255, 0.918);
    margin-top: 10vh;
    margin-bottom: 4rem;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    /* box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
      0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12) */
    @media screen and (max-width: $tablet) {
      width: 60%;
    }
    @media screen and (max-width: $bigPhone) {
      width: 80%;
    }
  }
}
.reg-btn {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}
.googleLogin {
  display: flex;
  font-family: $content-font;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  img {
    margin-right: 0.5rem;
    width: 20px;
  }
  p {
    padding-bottom: 0.1rem;
    color: #236b6b;
    @media screen and (max-width: $phone) {
      font-size: 0.8rem;
    }
    &:hover {
      color: $primary-color;
      cursor: pointer;
    }
  }
}
.errText {
  font-size: 0.7rem;
  color: red;
  margin-top: 0.2rem;
  font-family: $content-font;
}

//VIDEO on landing Page
* {
  box-sizing: border-box;
}
.video-background {
  position: relative;
  padding-top: 52.75%;
  h1 {
    @media screen and (max-width: $tablet) {
      font-size: 2rem;
    }
    @media screen and (max-width: $smallTablet) {
      font-size: 1.4rem;
    }
    @media screen and (max-width: $phone) {
      font-size: 1.2rem;
    }
  }
}

.video-background video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.videoTitle {
  position: absolute;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  display: flex;
  flex-direction: column;
  font-size: 2.5rem;
  align-items: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  line-height: 75px;
  letter-spacing: -0.015em;

  h1 {
    text-align: center;
  }

  button {
    color: white;
    background: #ff567f;
    border-radius: 6px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 29px;
    display: flex;
    align-items: center;
    text-align: center;
    /*   left: 16.78%;
    right: 17.6%;
    top: 21.73%;
    bottom: 22.5%; */
    &:hover {
      background-color: #ff91ac;
    }
  }
}

@media (min-aspect-ratio: 16/9) {
  .video-foreground {
    height: 300%;
    top: -100%;
  }
}
@media (max-aspect-ratio: 16/9) {
  .video-foreground {
    width: 300%;
    left: -100%;
  }
}
@media all and (max-width: 600px) {
  .vid-info {
    width: 50%;
    padding: 0.5rem;
  }
  .vid-info h1 {
    margin-bottom: 0.2rem;
  }
}
@media all and (max-width: 500px) {
  .vid-info .acronym {
    display: none;
  }
}
//landing page map section//
.chart-title {
  padding: 0 1rem;
  margin: 2rem 0;
  .MuiTypography-h3 {
    font-size: 2.5rem;
    font-weight: 600;
    @media screen and (max-width: $mediumTablet) {
      font-size: 1.8rem;
    }
    @media screen and (max-width: $bigPhone) {
      font-size: 1.4rem;
    }
    @media screen and (max-width: $iphoneX) {
      font-size: 1.2rem;
    }
  }
}
.location-icon {
  height: 30px;
  width: 30px;
  position: relative;
  bottom: 30px;
  right: 15px;
  color: red;
  z-index: -1;
  &:hover {
    color: $primary-color;
    cursor: pointer;
  }
}

//landing page feature section//
.home-content {
  margin-top: 10vh;
  .chat-feature {
    display: flex;
    justify-content: space-evenly;

    @media screen and (max-width: $mediumTablet) {
      flex-direction: column-reverse;
      margin-bottom: 5vh;
    }

    img {
      width: 400px;
      @media screen and (max-width: $mediumTablet) {
        width: 50%;
      }
    }
    .chat-text {
      @media screen and (max-width: $mediumTablet) {
        position: absolute;
        right: 20px;
        bottom: 8px;
      }

      .reach-out {
        padding-top: 5vh;
      }
      h3 {
        font-family: $titles-font;
        font-size: 1.8rem;
        font-weight: 600;
        margin-bottom: 2rem;
        padding-top: 15vh;
        @media screen and (max-width: $mediumTablet) {
          font-size: 1.2rem;
        }
        @media screen and (max-width: 450px) {
          font-size: 1rem;
          padding-top: 5vh;
        }
      }
      width: 50%;
      padding-left: 2rem;
      font-family: $content-font;
      p {
        line-height: 1.5rem;
        @media screen and (max-width: $mediumTablet) {
          font-size: 0.8rem;
        }
        @media screen and (max-width: 450px) {
          font-size: 0.7rem;
          line-height: 1rem;
        }
      }
    }
  }
}

//twitter feed styles//
.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease-in;
}

.example-exit {
  opacity: 1;
}
.example-exit.example-exit-active {
  opacity: 0.01;
  transition: opacity 1000ms ease-in;
}
.flex {
  display: flex;
  flex-direction: column-reverse;
}
.feedDiv {
  display: flex;
  justify-content: center;
  height: 250vh;
  overflow: hidden;
  width: 50%;
}
.tweet-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: center;
  .loader-text {
    font-family: $titles-font;
    margin-top: 5rem;
    margin-bottom: 1rem;
  }
  .feed-title {
    margin: 6rem 0 4rem 0;
    font-family: $content-font;
    font-weight: bold;
  }
  .twtr-logo {
    width: 40px;
  }
}
.map-text {
  padding: 2% 0;
  color: $primary-color;
  font-weight: bold;
}
// message-board styles
.card-container {
  max-height: calc(100vh - 350px);
}
.new_paper {
  @media screen and (max-width: $mediumTablet) {
    font-size: 1.8rem;
    width: auto;
    border: 1px solid purple;
  }
  @media screen and (max-width: $bigPhone) {
    position: absolute;
    right: 30px;
    width: 100px;
    height: 100px;
    font-size: 1.4rem;
  }
  @media screen and (max-width: $iphoneX) {
    font-size: 1.2rem;
    width: auto;
    border: 1px solid orange;
  }
}

.firstFive {
  // @media screen and (max-width: $mediumTablet) {
  //   display: flex;
  //   flex-direction: column;
  // }

  @media screen and (max-width: $bigPhone) {
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-width: $iphoneX) {
    display: flex;
    flex-direction: column;
  }
}

.secondFive {
  // @media screen and (max-width: $mediumTablet) {
  //   display: flex;
  //   flex-direction: column;
  // }

  @media screen and (max-width: $bigPhone) {
    display: flex;
    flex-direction: column;

  }

  @media screen and (max-width: $iphoneX) {
    display: flex;
    flex-direction: column;
  }
}

.MuiButtonBase-root.MuiCardActionArea-root img {
  @media screen and (max-width: $iphoneX) {
    height: 400px;
  }
}

.MuiCardContent-root {
  height: 150px;
}